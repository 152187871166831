<template>
  <div class="s-layout s-flex-direction--columns">
    <headerMain class="hovering" />
    <main class="s-expand">
      <router-view/>
    </main>
    <footerMain/>
    <Modal v-if="modalProblem" @close="closeModalProblem">
      <template v-slot:body>
        <div class="s-grid-cols--2-spacing--none--items--stretch-content--center-stackable-mobile s-height--expand">
          <div class="s-col s-swatch-default">
            <div class="s-halfWrapper--left s-height--expand s-flex-direction--column-content--center s-padding-y--xl  s-padding-right--xxl">
              <h1 class="animatedText s-title s-margin-bottom--l">Why we are building Spell?</h1>
              <p class="s-text-size--l">Over the past decade several global payments tech leaders, such as Stripe, Adyen, and Klarna have emerged. These companies were born as tech companies, invested millions building their exclusive platforms to win, and they are taking over the market.</p>
            </div>
          </div>
          <div class="s-col s-swatch-ink">
            <div class="s-halfWrapper--right s-height--expand s-flex-direction--column-content--center s-padding-y--xl s-padding-left--xxl">
              <h3 class="s-text--bold">As a consequence</h3>
              <ul class="bullets--small s-list--nobullets s-text-size--l s-margin-bottom--none">
                <li>Due to globalization, local players are starting to lose their market shares</li>
                <li>The technology expectations of clients are increasing</li>
                <li>Because of rapidly growing competition, profit margins are going down</li>
              </ul>
              <hr class="s-margin-y--l hrPink"/>
              <h3 class="s-text--bold s-margin-top--none"><span class="s-margin-right--s">🔮</span>Problem</h3>
              <p class="s-text-size--l">How, in this highly competitive market situation, can local payment service providers be able to continuously update their tech, offer lower prices, and maintain healthy finances all at the same time?</p>
              <p class="s-text-size--l">With Spell we are building a SaaS platform to solve this problem for our clients.</p>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <modalVideo v-if="modalVideo" />
  </div>
</template>
<script>
import headerMain from '@/components/headerMain.vue'
import footerMain from '@/components/footerMain.vue'

export default {
  components: {
    headerMain,
    footerMain
  },
  data () {
    return {
      modalVideo: false,
      modalProblem: false
    }
  },
  mounted () {
    this.emitter.on('show-modal-video', isOpen => {
      this.modalVideo = isOpen
      document.querySelector('body').classList.add('s-overflow--hidden')
    })
    this.emitter.on('show-modal-problem', isOpen => {
      this.modalProblem = isOpen
      document.querySelector('body').classList.add('s-overflow--hidden')
    })
  },
  methods: {
  }
}
</script>
